import React from "react";
import {
  Box,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import HODLVaultsLightImage from "../../images/vaults/hodl_vaults_light.png";
import HODLVaultsDarkImage from "../../images/vaults/hodl_vaults_dark.png";
import DepositNFTLightImage from "../../images/vaults/depositNFT_light.png";
import DepositNFTDarkImage from "../../images/vaults/depositNFT_dark.png";

export default function HODLVaults() {
  const { colorMode } = useColorMode();
  return (
    <VStack
      mx="auto"
      maxW="1290px"
      w="100%"
      minH="100%"
      py="10"
      px="8"
      spacing="24"
      justifyContent="space-between"
      textAlign="center"
    >
      <Stack
        direction={{ base: "column", lg: "row" }}
        alignItems="center"
        w="100%"
        minH="90vh"
        spacing="8"
        justifyContent="space-between"
      >
        <Box flex={{ base: 1, lg: 3 }}>
          <Image
            src={
              colorMode === "dark" ? HODLVaultsDarkImage : HODLVaultsLightImage
            }
            objectFit="contain"
          />
        </Box>
        <VStack
          flex={{ base: 1, lg: 2 }}
          maxW="70ch"
          alignItems="flex-start"
          spacing="3"
        >
          <Heading>HODL Vault</Heading>
          <VStack
            w="100%"
            alignItems="flex-start"
            textAlign="start"
            fontSize={{ base: "md", md: "lg" }}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Text>
              <Highlight>HODL Vault</Highlight> is the solution we idealized for
              the stress that comes from keeping up with the pumps and dumps of
              the crypto market.
            </Text>
            <Text>
              The idea behind it is very simple. You choose an asset, the amount
              to deposit and the date in which you wish to unlock this deposit.
              By doing so, you lose access to that asset and remove yourself
              from the shenanigans of green and red candlesticks.
            </Text>
            <Text>
              Once the time you set has passed you can withdraw your money.
            </Text>
            <Text>
              The <Highlight>HODL Vault</Highlight> will also serve as a way of
              distribution of our governance token (<Highlight>CARPE</Highlight>
              ). Depending on the token, amount and time of your deposit, a{" "}
              <Highlight>CARPE</Highlight> reward is calculated for you to
              claim.
            </Text>
          </VStack>
        </VStack>
      </Stack>

      <Stack
        direction={{ base: "column-reverse", lg: "row" }}
        alignItems="center"
        w="100%"
        minH="90vh"
        spacing="8"
        justifyContent="space-between"
      >
        <VStack flex={{ base: 1, lg: 2 }} alignItems="flex-start">
          <Heading>Deposit NFT</Heading>
          <VStack
            w="100%"
            alignItems="flex-start"
            textAlign="start"
            fontSize={{ base: "md", md: "lg" }}
            color={useColorModeValue("gray.700", "gray.200")}
          >
            <Text>
              Once you deposit in our <Highlight>HODL Vault</Highlight> you
              receive an NFT to represent the deposit.
            </Text>
            <Text>
              This allows for some fun stuff besides withdrawing your deposit.
              You can now:
            </Text>
            <UnorderedList pl="6">
              <ListItem>
                <Text>
                  <Highlight>Increase</Highlight> the deposited amount without
                  changing the lock time
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <Highlight>Transfer</Highlight> your deposit to another
                  address
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <Highlight>Sell</Highlight> your deposit in our soon to come
                  Marketplace
                </Text>
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
        <Box flex={{ base: 1, lg: 3 }}>
          <Image
            src={
              colorMode === "dark" ? DepositNFTDarkImage : DepositNFTLightImage
            }
            objectFit="contain"
          />
        </Box>
      </Stack>
    </VStack>
  );
}

const Highlight = ({ children, ...props }) => (
  <Text as="span" color="brand.500" {...props}>
    {children}
  </Text>
);
