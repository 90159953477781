import React from "react";
import {
  Container,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
  Tooltip,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import CarpeLogo from "../images/carpe.svg";
import { Link } from "gatsby";
import { APP_URL } from "../utils/consts";

export default function Footer() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box as="footer" w="100%">
      <Container maxW="1290px">
        <SimpleGrid
          w="100%"
          alignItems="flex-start"
          spacing="10"
          justifyContent="space-between"
          py="8"
          columns={{ base: 2, md: 4 }}
        >
          <VStack alignItems="flex-start">
            <HStack>
              <Image src={CarpeLogo} h="7" />
              <Heading fontWeight="medium" fontSize="2xl">
                Carpe DAO
              </Heading>
            </HStack>
            <Text lineHeight="1.2">Building the HODL Protocol</Text>
            <Text
              lineHeight="1.2"
              as="a"
              href="mailto:contact@carpedao.org"
              color="brand.500"
              _hover={{ textDecoration: "underline" }}
            >
              contact@carpedao.org
            </Text>
          </VStack>
          {isMobile ? (
            <FooterGroup
              title="Community"
              items={[
                {
                  href: "https://twitter.com/0xcarpedao",
                  children: "Twitter",
                },
                {
                  href: "https://t.me/carpeDAO",
                  children: "Telegram",
                },
              ]}
            />
          ) : (
            <FooterGroup
              title="Protocol"
              items={[
                {
                  href: APP_URL,
                  children: "App",
                  target: "_self",
                },
                {
                  href: "https://github.com/carpedao",
                  children: "Github",
                },
                {
                  href: APP_URL + "/whitepaper.pdf",
                  children: "Whitepaper",
                },
              ]}
            />
          )}
          <FooterGroup
            title="Governance"
            items={[
              {
                to: "/",
                children: "Vote",
                isDisabled: true,
              },
              {
                to: "/",
                children: "Contribute",
                isDisabled: true,
              },
            ]}
          />
          {isMobile ? (
            <FooterGroup
              title="Protocol"
              items={[
                {
                  href: APP_URL,
                  children: "App",
                  target: "_self",
                },
                {
                  href: "https://github.com/carpedao",
                  children: "Github",
                },
                {
                  href: APP_URL + "/whitepaper.pdf",
                  children: "Whitepaper",
                },
              ]}
            />
          ) : (
            <FooterGroup
              title="Community"
              items={[
                {
                  href: "https://twitter.com/0xcarpedao",
                  children: "Twitter",
                },
                {
                  href: "https://t.me/carpeDAO",
                  children: "Telegram",
                },
              ]}
            />
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

function FooterLink({ to, href, target, isDisabled, children }) {
  const textProps = to
    ? {
        as: isDisabled ? "p" : Link,
        to,
      }
    : {
        as: isDisabled ? "p" : "a",
        href,
        target: target || "_blank",
      };

  return (
    <Tooltip placement="top" label={isDisabled ? "Coming soon" : null}>
      <Text
        {...textProps}
        cursor={isDisabled ? "default" : "pointer"}
        color={isDisabled ? "gray.500" : "inherit"}
        _hover={{ color: isDisabled ? "gray.500" : "brand.500" }}
      >
        {children}
      </Text>
    </Tooltip>
  );
}

function FooterGroup({ title, items }) {
  return (
    <VStack alignItems="flex-start">
      <Heading as="h2" fontSize="xl">
        {title}
      </Heading>
      {items.map((item, idx) => (
        <FooterLink key={idx} {...item} />
      ))}
    </VStack>
  );
}
