import React from "react";
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Image,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  DrawerHeader,
  useColorMode,
  Icon,
} from "@chakra-ui/react";
import CarpeLogo from "../images/carpe.svg";
import CarpeWhite from "../images/carpe-white.svg";
import { Link } from "gatsby";
import { FiMenu, FiMoon, FiSun } from "react-icons/fi";
import { APP_URL } from "../utils/consts";

export default function Header() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack w="100%" justifyContent="space-between" as="nav" py="4">
      <HStack as={Link} to="/" spacing="2">
        <Image src={CarpeLogo} h="7" />
        <Heading fontSize="2xl" fontWeight="medium">
          Carpe DAO
        </Heading>
      </HStack>
      {isMobile ? (
        <HeaderDrawer />
      ) : (
        <HStack spacing="5" fontSize="lg">
          <HeaderLink
            variant="primary"
            color="black"
            href={`${APP_URL}/whitepaper.pdf`}
            target="_blank"
          >
            Whitepaper
          </HeaderLink>
          <Button
            variant="primary"
            rightIcon={<Image h="4" src={CarpeWhite} />}
            as="a"
            href={APP_URL}
          >
            Enter Carpe
          </Button>
          <Icon
            cursor="pointer"
            onClick={toggleColorMode}
            as={colorMode === "dark" ? FiSun : FiMoon}
            _hover={{
              color: "brand.500",
            }}
          />
        </HStack>
      )}
    </HStack>
  );
}

function HeaderDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <>
      <IconButton
        variant="ghost"
        icon={<FiMenu />}
        onClick={onOpen}
        color="brand.600"
        fontSize="2xl"
      />
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent h="100%">
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            <VStack
              py="8"
              alignItems="flex-start"
              justifyContent="space-between"
              h="100%"
              spacing="7"
              fontSize="2xl"
            >
              <HeaderLink href={`${APP_URL}/whitepaper.pdf`} target="_blank">
                Whitepaper
              </HeaderLink>
              <HStack w="100%" justifyContent="space-between">
                <Button
                  variant="primary"
                  rightIcon={<Image h="5" src={CarpeWhite} />}
                  as="a"
                  href={APP_URL}
                >
                  Enter Carpe
                </Button>
                <Icon
                  cursor="pointer"
                  onClick={toggleColorMode}
                  as={colorMode === "dark" ? FiSun : FiMoon}
                  _hover={{
                    color: "brand.500",
                  }}
                />
              </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function HeaderLink({ to, href, target, children }) {
  return (
    <Text
      as={href ? "a" : Link}
      to={to}
      href={href}
      target={target}
      _hover={{ color: "brand.500" }}
      fontWeight="400"
    >
      {children}
    </Text>
  );
}
