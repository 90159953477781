import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import "./layout.css";
import { Box, Container, useTheme, VStack } from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";
import Seo from "./seo";

const Layout = ({ children }) => {
  const { image, site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      image: file(relativePath: { eq: "share_image.png" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const theme = useTheme();
  const primary = theme.colors.brand[500];

  return (
    <>
      <Box h="100%" w="100%">
        <VStack h="100%" w="100%">
          <Seo image={image.childImageSharp.resize} />
          <Container maxW="1290px">
            <Header siteTitle={site.siteMetadata?.title || `CarpeDAO`} />
          </Container>
          <Box
            flex={1}
            width="100%"
            height="100%"
            overflowY="auto"
            bgGradient={`linear(to-t, ${primary}45 0%, ${primary}00 50%, transparent 50%)`}
          >
            {children}
            <Footer />
          </Box>
        </VStack>
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
