import USDT from "../images/crypto/USDT.svg";
import BTC from "../images/crypto/BTC.svg";
import AAVE from "../images/crypto/aave.png";
import COMP from "../images/crypto/comp.png";
import CRV from "../images/crypto/crv.png";
import DAI from "../images/crypto/dai.png";
import MKR from "../images/crypto/mkr.png";
import UNI from "../images/crypto/uni.png";
import USDC from "../images/crypto/usdc.png";
import WETH from "../images/crypto/weth.png";
import Carpe from "../images/carpe.png";

export const APP_URL = "https://app.carpedao.org";

export const tokens = [
  {
    name: "Aave",
    symbol: "AAVE",
    logoURI: AAVE,
  },
  {
    name: "Compound",
    symbol: "COMP",
    logoURI: COMP,
  },
  {
    name: "Curve DAO Token",
    symbol: "CRV",
    logoURI: CRV,
  },
  {
    name: "Dai Stablecoin",
    symbol: "DAI",
    logoURI: DAI,
  },
  {
    name: "Maker",
    symbol: "MKR",
    logoURI: MKR,
  },
  {
    name: "Uniswap",
    symbol: "UNI",
    logoURI: UNI,
  },
  {
    name: "USDCoin",
    symbol: "USDC",
    logoURI: USDC,
  },
  {
    name: "Tether USD",
    symbol: "USDT",
    logoURI: USDT,
  },
  {
    name: "Wrapped BTC",
    symbol: "WBTC",
    logoURI: BTC,
  },
  {
    name: "Wrapped Ether",
    symbol: "WETH",
    logoURI: WETH,
  },
  {
    name: "Carpe DAO",
    symbol: "CARPE",
    logoURI: Carpe,
  },
];
