import React from "react";
import {
  Heading,
  VStack,
  Text,
  Image,
  Button,
  HStack,
  Icon,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import CarpeWhite from "../../images/carpe-white.svg";
import { RiOpenSourceFill } from "react-icons/ri";
import { FaUserShield, FaGlobeAmericas } from "react-icons/fa";
import { APP_URL, tokens } from "../../utils/consts";
import { BsQuestion } from "react-icons/bs";

export default function Hero() {
  return (
    <VStack
      w="100%"
      h="100%"
      py="10"
      spacing="2"
      justifyContent="space-between"
      textAlign="center"
    >
      <VStack flex={1} justifyContent="center" spacing="6">
        <Heading fontSize="6xl">HODLing for Everyone</Heading>
        <VStack>
          <AvatarGroup>
            {tokens.map((token) => (
              <Avatar
                key={token.symbol}
                src={token.logoURI}
                icon={<Icon as={BsQuestion} />}
                boxSize={{ base: "10", sm: "12" }}
                transition="all 0.2s"
                _hover={{ transform: "scale(1.1)", zIndex: 1 }}
                cursor="pointer"
                as="a"
                href={`${APP_URL}/hodl/${token.symbol}`}
              />
            ))}
          </AvatarGroup>
          <Text textTransform="uppercase">Powered by Ethereum</Text>
        </VStack>
        <Button
          variant="primary"
          rightIcon={<Image h="4" src={CarpeWhite} />}
          as="a"
          href={APP_URL}
        >
          Enter Carpe
        </Button>
      </VStack>

      <HStack
        spacing={{ base: "8", md: "24" }}
        justifyContent="space-between"
        px={{ base: "8", md: "24" }}
        w="100%"
        fontSize={{ base: "md", md: "xl" }}
      >
        <VStack flex={1}>
          <Icon boxSize={{ base: "8", md: "12" }} as={RiOpenSourceFill} />
          <Text>Open-source</Text>
        </VStack>
        <VStack flex={1}>
          <Icon boxSize={{ base: "8", md: "12" }} as={FaGlobeAmericas} />
          <Text>Decentralised</Text>
        </VStack>
        <VStack flex={1}>
          <Icon boxSize={{ base: "8", md: "12" }} as={FaUserShield} />
          <Text>Non-custodial</Text>
        </VStack>
      </HStack>
    </VStack>
  );
}
